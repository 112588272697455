import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const ShippingDeliveryPolicy = () => {
  return (
    <>
      <Header />
      <div className="container">
        <h2 className="content-head">Shipping & Delivery Policy</h2>
        <div className="content-seperator"></div>
        <p className="updated-date">Last updated on Jul 6th 2024</p>
        <p style={{marginTop: '2rem'}} className="content-text">
        Shipping is not applicable for business.
        </p>

        <p className="disclaimer">
          Disclaimer: The above content is created at MOHAMED SAJJAD's sole
          discretion. Razorpay shall not be liable for any content provided here
          and shall not be responsible for any claims and liability that may
          arise due to merchant&apos;s non-adherence to it.
        </p>
      </div>
      <Footer/>
    </>
  );
};

export default ShippingDeliveryPolicy;
