import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const CancellationRefundPolicy = () => {
  return (
    <>
      <Header />
      <div className="container">
        <h2 className="content-head">Cancellation & Refund Policy</h2>
        <div className="content-seperator"></div>
        <p className="updated-date">Last updated on Jul 6th 2024</p>
        <p className="content-text">
        FACE COACHING CENTRE believes in helping its customers as far as possible, and has therefore a liberal cancellation policy. Under this policy:
        </p>
        {/* <p className="content-text">
          <strong>
            Your use of the website and/or purchase from us are governed by
            following Terms and Conditions:
          </strong>
        </p> */}
        <ul className="unorder-list">
          <li className="list-item">
          Cancellations will be considered only if the request is made within 7 days of placing the order. However, the cancellation request may not be entertained if the orders have been communicated to the vendors/merchants and they have initiated the process of shipping them.
          </li>
          <li className="list-item">
          FACE COACHING CENTRE does not accept cancellation requests for perishable items like flowers, eatables etc. However, refund/replacement can be made if the customer establishes that the quality of product delivered is not good.
          </li>
          <li className="list-item">
          In case of receipt of damaged or defective items please report the same to our Customer Service team. The request will, however, be entertained once the merchant has checked and determined the same at his own end. This should be reported within 7 days of receipt of the products.
          </li>
          <li className="list-item">
          In case you feel that the product received is not as shown on the site or as per your expectations, you must bring it to the notice of our customer service within 7 days of receiving the product. The Customer Service Team after looking into your complaint will take an appropriate decision.
          </li>
          <li className="list-item">
          In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.
          </li>
          <li className="list-item">
          In case of any Refunds approved by the FACE COACHING CENTRE, it’ll take 6-8 days for the refund to be processed to the end customer.
          </li>
        </ul>

        <p className="disclaimer">
        Disclaimer: The above content is created at MOHAMED SAJJAD's sole discretion. Razorpay shall not be liable for any content provided here and shall not be responsible for any claims and liability that may arise due to merchant&apos;s non-adherence to it.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default CancellationRefundPolicy;
