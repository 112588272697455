import tutorfe from '../assets/tutor1.png'
import tutorm from '../assets/tutor2.png'

export const instructorData = [
    {
        id : 1,
        name : 'Ayisha Meharin',
        description : '8 Year In Financial Accounting',
        department : 'Financial Accounting',
        image: tutorfe,
    },
    {
        id : 2,
        name : 'john abraham',
        description : '10 year in business statistics',
        department : 'Business statistics',
        image: tutorm,
    },
    {
        id : 3,
        name : 'Ayisha Meharin',
        description : '8 Year In Financial Accounting',
        department : 'Financial Accounting',
        image: tutorfe,
    },
    {
        id : 4,
        name : 'john abraham',
        description : '10 year in business statistics',
        department : 'Business statistics',
        image: tutorm,
    },
    {
        id : 5,
        name : 'Ayisha Meharin',
        description : '8 Year In Financial Accounting',
        department : 'Financial Accounting',
        image: tutorfe,
    },
    {
        id : 6,
        name : 'john abraham',
        description : '10 year in business statistics',
        department : 'Business statistics',
        image: tutorm,
    },
    {
        id : 7,
        name : 'Ayisha Meharin',
        description : '8 Year In Financial Accounting',
        department : 'Financial Accounting',
        image: tutorfe,
    },
    {
        id : 8,
        name : 'john abraham',
        description : '10 year in business statistics',
        department : 'Business statistics',
        image: tutorm,
    }
]