import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom' 
import Home from '../pages/Home/Home'
import TermsConditons from '../pages/TermsConditions/TermsConditons'
import CancellationRefundPolicy from '../pages/CancellationRefundPolicy/CancellationRefundPolicy'
import ShippingDeliveryPolicy from '../pages/ShippingDeliveryPolicy/ShippingDeliveryPolicy'
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy'


const LayoutRouter = () => {
  return (
    <div>
         <BrowserRouter>
              <Routes>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/terms&conditions' element={<TermsConditons/>}></Route>
                <Route path='/cancellation&refund-policy' element={<CancellationRefundPolicy/>}></Route>
                <Route path='/shipping&delivery-policy' element={<ShippingDeliveryPolicy/>}></Route>
                <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
              </Routes>
         </BrowserRouter>
    </div>
  )
}

export default LayoutRouter